.container {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 25;
    transform: translateY(0);
    transition: transform 0s 0s;
}

.backdrop {
    composes: container;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    overflow: hidden;
    opacity: 1;
    transition: backdrop-filter .5s, opacity .5s;
}

.popup {
    display: grid;
    grid-template:
        "banner content" 1fr
        / 5x 3fr;
    background-color: var(--Color-base);
    color: var(--Color-base-text);
    flex-flow: column;
    margin: 5x;
    border-radius: 1x;
    box-shadow: 0 2x 3x var(--Color-base-shadow);
    overflow: auto;
    width: 100%;
    max-width: 60ch;
    max-height: 90vh;
    transform: translateY(0);
    opacity: 1;
    z-index: 30;
    transition: transform .5s, opacity .5s;

    @media (width < 740px) {
        & {
            grid-template:
                "banner" 2x
                "content" 1fr
                / 1fr;
        }
    }
}

.hiddenPopup {
    transform: translateY(-120%);
    transition: transform 0s .5s;

    & .backdrop {
        backdrop-filter: blur(0);
        opacity: 0;
    }

    & .popup {
        opacity: 0;
        transform: translateY(10%);
    }
}

.closeButton {
    grid-area: content;
    position: sticky;
    top: 0;
    padding: 1x;
    align-self: start;
    justify-self: end;
    transform: translateZ(0);
    z-index: 2;

    &:hover, &:focus {
        & .closeIcon {
            background-color: var(--Color-primary-shadow);
            color: var(--Color-primary-text);
        }
    }

    &:active {
        & .closeIcon {
            background-color: var(--Color-primary);
            color: var(--Color-primary-text);
            transition: background-color .1s, color .1s;
        }
    }
}

.closeIcon {
    width: 5x;
    height: 5x;
    border-radius: 50%;
    transition: background-color .3s, color .3s;
}

.bannerContainer {
    grid-area: banner;
    background-color: var(--Color-primary);
}

.content {
    grid-area: content;
    padding: 5x 2x;
    max-width: 50ch;
    margin: * auto;
    user-select: none;
}

.formLabel {
    font-size: 25px;
    text-align: center;
}

.input {
    display: block;
    font-size: 1.2rem;
    position: relative;
    margin: 1.5em * 1rem;

    &Label {
        display: block;
        user-select: none;
        transform: translateY(110%);
        transform-origin: bottom left;
        pointer-events: none;
        position: absolute;
        top: -1.2em;
        left: 0;
        right: 0;
        font-size: .95em;
        transition: transform .3s;
        opacity: .8;
    }

    &Field {
        z-index: 1;
        display: block;
        width: 100%;
        padding: 1x *;
        border: none;
        border-bottom: .3x solid var(--Color-primary);
        color: var(--Color-primary);
        background-color: transparent;
        font-size: inherit;
        outline: transparent;
        font-weight: bold;

        &:focus {
            border-color: var(--Color-secondary);
        }

        &:focus, &:not(:placeholder-shown) {
            & + .inputLabel {
                transform: translateY(0) scale(.9);
            }
        }

        &:focus:not(:placeholder-shown):invalid,
        &:not(:placeholder-shown):invalid {
            border-color: var(--Color-error);
            & + .inputLabel {
                color: var(--Color-error);
            }
        }
    }
}

.messageBanner {
    display: block;
    background-color: var(--Color-secondary);
    color: var(--Color-secondary-text);
    padding: 1x 2x;
    border-radius: 1x;
    margin: 1x *;
    max-height: 0;
    opacity: 0;
    transition: opacity .5s, max-height .5s;
    will-change: max-height;

    &Success {
        max-height: 20x;
        opacity: 1;
    }

    &Error {
        max-height: 20x;
        opacity: 1;
        background-color: var(--Color-error);
        color: var(--Color-error-text);
    }
}
