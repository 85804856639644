.app {
    min-height: 100vh;
    scroll-behavior: smooth;
    animation: firstLoad .4s .3s ease-in-out both;
}

@keyframes firstLoad {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
