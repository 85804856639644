html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-size: 2.5x;
    font-family: "Lato", sans-serif;
    line-height: 1.5;
    background-color: var(--Color-base);
    color: var(--Color-base-text);
}

img {
    height: 100%;
    max-width: 100%;
}
