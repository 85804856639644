:root {
    --Navbar-padding: 1.5x 5x;
    --Section-minHeight: 30vh;
    --Section-padding: 5x;
    --Section-padding-vertical: 10x;
    --Section-container-width: 100ch;
    --Section-content-width: 50%;
    --Row-gap: 5em;
    --Image-width: 100%;
}

.navbarWrapper {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--Color-glass);
    backdrop-filter: blur(1x);
    transition: background-color .2s, backdrop-filter .2s;
    z-index: 20;
}

.navbar {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--Navbar-padding);

    @media (max-width: 450px) {
        & {
            flex-flow: column;

            & .navigation {
                padding-top: .5em;
            }
        }
    }
}

.logo {
    display: block;
    background-image: url("../img/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 15x;
    height: 6x;
    transform-origin: left top;
    transition: transform .2s;
}

.logoFooter {
    composes: logo;
    height: 6x;
    width: 20x;
    background-image: url("../img/logo-white.svg");
}

.logoStem5Footer {
    composes: logo;
    background-image: url("../img/stem5-white.png");
}

.wave {
    padding-bottom: 10vw;
    position: relative;
	background: linear-gradient(189deg, var(--Color-brand-primary), var(--Color-brand-secondary));

    &::after {
        z-index: 1;
        position: absolute;
        content: '';
        display: block;
        background-image:
            url("../img/waves/wave-1.svg"),
            url("../img/waves/wave-2.svg"),
            url("../img/waves/wave-3.svg"),
            url("../img/waves/wave-4.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 100% 100%;
        top: -2px;
        bottom: -2px;
        left: 0;
        right: 0;
    }
}

.waveReverse {
    padding-bottom: 0;
    padding-top: 10vw;

    &::after {
        transform: scaleY(-1);
    }
}

.hero {
    /*composes: wave;*/
    --Button-backgroundColor: var(--Color-primary-text);
    --Button-color: var(--Color-tertiary);
    --Section-padding-vertical: 0;
    background-color: var(--Color-primary);
	background: linear-gradient(189deg, var(--Color-brand-primary), var(--Color-brand-secondary));
    color: var(--Color-primary-text);

    @media (max-width: 768px) {
        & {
            --Image-width: 70%;
        }

        & .contentBlock {
            padding-top: 5em;
        }
    }
}

.navigation {
    display: block;
    transition: transform .2s;
}

.isOnTop {
    backdrop-filter: blur(0);
    background-color: transparent;

    & .logo {
        transform: scale(2) translateY(2x);
    }

    & .navigation {
        transform: translate(-2x, 7x);
    }
}

.sectionSeparator {
    display: block;

    @media (width < 740px) {
        & {
            height: 10vh;
        }
    }

    &Collapsed {
        height: 5vh;
    }
}

.section {
    display: grid;
    grid-template:
        ". ....... ." var(--Section-padding-vertical, var(--Section-padding))
        ". content ." 1fr
        ". ....... ." var(--Section-padding-vertical, var(--Section-padding))
        / minmax(var(--Section-padding), 1fr) minmax(auto, var(--Section-container-width)) minmax(var(--Section-padding), 1fr);
    align-items: center;
}

.left {
    justify-items: start;
}

.right {
    justify-items: end;
}

.center {
    justify-items: center;
}

.top {
    @media (width < 740px) {
        & {
            align-items: start;
        }
    }
}

.bottom {
    @media (width < 740px) {
        & {
            align-items: end;
        }
    }
}

.content {
    z-index: 2;
    grid-area: content;
    width: var(--Section-content-width);
}

.isContentWide {
    --Section-content-width: 100%;
}

.isContentShort {
    --Section-minHeight: auto;
}

.row {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: var(--Row-gap);

    &Stretch {
        align-items: stretch;
    }

    &Reverse {
        flex-flow: row-reverse;
    }

    &Narrow {
        --Row-gap: 1.5em;
    }

    &Align {
        &Top {
            align-items: flex-start;
        }

        &Bottom {
            align-items: flex-end;
        }
    }

    @media (width < 740px) {
        & {
            --Row-gap: 1em;
           flex-flow: column;
           align-items: center;
        }
    }

    & > * {
        flex: 1;
    }
}

.contentBlock {
    display: block;
    flex: 3;
}

.detail {
    display: none;
    grid-template:
        ".... ... ...." 1fr
        ".... ... ...." 1fr
        ".... ... text" 3fr
        / 1fr 2fr 1fr;

    @media (width < 740px) {
        & {
            grid-template:
                ".... ...." 40vh
                ".... ...." 10x
                "text text" auto
                / 1fr 1fr;
        }
    }
}

.detailLine {
    display: block;
    grid-row: 2;
    grid-column: -1 / -2;
    transform: translateX(-50%);
    border: 0 solid var(--Color-primary);
    border-width: .4x .4x 0 0;

    @media (width < 740px) {
        & {
            grid-row: 2;
            grid-column: 1 / 2;
            border-top-width: 0;
            transform: translateX(0);
        }
    }
}

.detailText {
    display: block;
    grid-area: text;
    background-color: rgba(255, 255, 255, .8);
    padding: 2x;
    text-align: center;
    border-radius: 1x;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 2x var(--Color-base-shadow);

    & > * {
        margin: 0;
    }
}

.footerSection {
    margin-bottom: 1em;
    display: flex;
    flex-flow: column;
    gap: .5em;

    @media (width < 740px) {
        & {
            align-items: center;
            text-align: center;
        }
    }
}

.footer {
    --Section-padding-vertical: 5x;
    /*composes: wave;*/
    /*composes: waveReverse;*/
    background-color: var(--Color-primary);
	background: linear-gradient(189deg, var(--Color-brand-primary), var(--Color-brand-secondary));
    color: var(--Color-primary-text);

    &Info {
        composes: footerSection;
        font-size: .85em;
        line-height: 1;
    }

    &Small {
        text-align: center;
        font-size: .7em;
    }

    & a {
        color: var(--Color-primary-text);
        text-decoration: none;
        opacity: 1;
        transition: opacity .2s;

        &:hover, &:focus {
            opacity: .6;
        }

        &:active {
            opacity: .9;
        }

        &[href=""] {
            opacity: 1;
            cursor: text;
            pointer-events: none;
        }
    }
}

.image {
    flex: 2;
    display: flex;
    justify-content: center;

    & img {
        width: var(--Image-width);
        height: auto;
    }
}

@media (width < 740px) {
    :root {
        --Section-content-width: 100%;
        --Section-padding-vertical: 8x;
        --Image-width: 90%;
    }
}
