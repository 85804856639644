:root {
    --Color-brand-primary: #0d65a8;
    --Color-brand-secondary: #039BE5;
    --Color-brand-secondary-transparent: rgba(3, 155, 229, .2);
    --Color-brand-tertiary: #004275;

    --Color-base: #ffffff;
    --Color-base-text: var(--Color-brand-primary);
    --Color-base-shadow: #0000007e;
    --Color-glass: rgba(255, 255, 255, .8);
    --Color-primary: var(--Color-brand-primary);
	--Color-primary-text:#ffffff;
    --Color-primary-shadow: var(--Color-brand-secondary);
    --Color-secondary: var(--Color-brand-secondary);
    --Color-secondary-transparent: var(--Color-brand-secondary-transparent);
    --Color-secondary-text: var(--Color-brand-tertiary);
    --Color-tertiary: var(--Color-brand-tertiary);
    --Color-tertiary-text: #ffffff;
    --Color-error: #ed6f6f;
    --Color-error-text: #ffffff;
}

/* Dark mode */
/*@media (prefers-color-scheme: dark) {*/
/*    :root {*/
/*        --Color-base: #1e2e24;*/
/*        --Color-base-text: #72c28f;*/
/*        --Color-glass: rgba(31, 99, 56, .7);*/
/*    }*/
/*}*/
